@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.wallet-connection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 1.25rem;
  box-sizing: border-box;
  text-align: center;
  /* background-image: url('/assets/ui/walletconnect/bg.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.wallet-connection-content {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wallet-connection-container h2 {
  margin-bottom: .6rem;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -4rem;
}

.wallet-connection-container p {
  /* margin-bottom: 30px; */
  font-family: "JetBrains Mono", monospace;
  font-size: 16px;
  color: #ffffff;
  line-height: 1.5;
  margin-bottom: 14rem;
}

/* Override TonConnectButton styles */
.wallet-connection-container button {
  background-color: #f8ad06 !important;
  border: none !important;
  color: #000000 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  padding: 1rem 3.5rem !important;
  font-size: 16px !important;
  border-radius: 16px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  width: 100% !important;
  max-width: 300px !important;
  font-family: "QK Star", sans-serif !important;
}


.wallet-connection-container button:hover {
  background-color: #e59f05 !important;
}

#pay-button {
  background-color: #f8ad06 !important;
  border: none !important;
  color: #000000 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  /* padding: 1rem 3.5rem !important; */
  font-size: 16px !important;
  border-radius: 16px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  width: 80% !important;
  max-width: 300px !important;
  font-family: "QK Star", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pay-button img {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}

#pay-button:hover {
  background-color: #e59f05 !important;
}

@media (max-width: 768px) {
  .wallet-connection-container h2 {
    font-size: 20px;
  }

  .wallet-connection-container p {
    font-size: 14px;
  }

  .wallet-connection-container button {
    font-size: 14px !important;
    padding: 10px 20px !important;
  }
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background:
    linear-gradient(0deg, rgba(255, 255, 255, 0.5) 30%, transparent 30% 70%, rgba(255, 255, 255, 1) 70%) 50% / 8% 100%,
    linear-gradient(90deg, rgba(255, 255, 255, 0.25) 30%, transparent 30% 70%, rgba(255, 255, 255, 0.75) 70%) 50% / 100% 8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.loader::before,
.loader::after {
   content: "";
   grid-area: 1/1;
   border-radius: 50%;
   background: inherit;
   opacity: 0.915;
   transform: rotate(30deg);
}
.loader::after {
   opacity: 0.83;
   transform: rotate(60deg);
}
@keyframes l23 {
  100% {transform: rotate(1turn)}
}