.lore {
	display: flex;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.intro {
	text-align: center;
	animation: intro ease-out 7s;
	color: #fff;
	font-weight: 400;
	font-size: 3rem;
	opacity: 0;
	font-family: "QK Star", sans-serif;
	margin: auto 0;
}

@keyframes intro {
	0% {
		opacity: 0;
		transform: scale(1.8) rotateX(20deg);
		filter: blur(5px);
	}

	20% {
		opacity: 1;
		transform: scale(1.4) rotateX(15deg);
		filter: blur(2px);
	}

	80% {
		opacity: 1;
		transform: scale(1) rotateX(0deg);
		filter: blur(0px);
	}

	100% {
		opacity: 0;
		transform:scale(0.8) rotateX(-10deg);
		filter: blur(2px);
	}
}

p {
	color: #f8ad06;
}

.board {
	font-family: "QK Star", sans-serif;
	transform: perspective(18.75rem) rotateX(25deg);
	transform-origin: 50% 100%;
	text-align: justify;
	position: absolute;
	font-weight: bold;
	font-size: 1.25rem;
	overflow: hidden;
	height: 70%;
	width: calc(100% - 2rem);
    top: -9rem;
    left: 0;
    right: 0;
    margin: auto;
}

.content {
	animation: scroll 50s linear 6s;
	position: absolute;
	top: 100%;
}

@keyframes scroll {
	0% {
		top: 100%;
	}

	100% {
		top: -170%;
	}
}