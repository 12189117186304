.refer-container {
    width: 80%;
    padding: 1rem 2rem;
    background-color: rgba(35, 39, 42, 0.7);
    border-radius: 1rem;
    margin-bottom: -1rem;
  }

  .refcontent {
    margin-top: 0.5rem;
    font-size: .8rem;
  }
  
  .refer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .refer-header h2 {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .refer-link-button {
    all: unset;
    color: #f8ad06;
    font-size: .8rem;
  }

  .refer-grid {
    display: flex;
    align-items: center;
  }
  
  .copy-status {
    color: #28a745;
    font-size: 0.5em;
    margin-left: 1rem;
  }