.main-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  padding: 20px;
  color: #ffffff;
}

.profile-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  background-color: #23272a;
  margin-top: 1rem;
  padding: .3rem 1rem;
  border-radius: 8px;
}

.profile-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-info h2 {
  font-size: 1rem;
}

.profile-section p {
  font-size: 1rem;
  color: #f8ad06;
}

.play-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0rem;
  gap: 2rem;
}

.energy-section {
  font-size: 1rem;
  text-align: center;
}

.play-section img {
  width: 10rem;
  height: 10rem;
}

.play-button {
  background-color: #f8ad06 !important;
  border: none !important;
  color: #000000 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  /* padding: 1rem 3.5rem !important; */
  font-size: 16px !important;
  border-radius: 16px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
  width: 60% !important;
  max-width: 300px !important;
  font-family: "QK Star", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-items {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  justify-content: space-around;
  width: 80%;
  background-color: #23272a;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  border-radius: 24px;
}

.menu-items button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  color: #9C9C9C;
  cursor: pointer;
}

.menu-items span {
  font-size: .5rem;
  font-family: "QK Star", sans-serif !important;
}

.menu-items img {
  width: 1.2rem;
  height: 1.2rem;
  margin-bottom: .4rem;
}

.menu-items button.active {
  color: #ffffff;
}

.menu-items button.active img {
  filter: brightness(0) invert(1);
}

@media (max-width: 768px) {
  .profile-image {
    width: 2rem;
    height: 2rem;
  }

  .profile-info h2 {
    font-size: .75rem;
  }

  .profile-section p {
    font-size: .75rem;
  }

  .profile-info h2 {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .play-button {
    padding: 12px 30px;
    font-size: 18px;
  }

}

@media (max-width: 320px) {
  .profile-image {
    width: 40px;
    height: 40px;
  }

  .profile-info h2 {
    font-size: 18px;
  }

  .profile-info p {
    font-size: 14px;
  }


  .play-button {
    padding: 10px 25px;
    font-size: 16px;
  }

  .menu-items img {
    width: 20px;
    height: 20px;
  }
}
