

.stats-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  padding: 1rem 2rem;
  background-color: rgba(35, 39, 42, 0.7);
  border-radius: 1rem;
  margin-bottom: -1rem;
}

.stat-container-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.stat-container-item-header {
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}

.stat-container-item-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: #f8ad06;
}

.assets-container {
  width: 80%;
  padding: 1rem 2rem;
  background-color: rgba(35, 39, 42, 0.7);
  border-radius: 1rem;
  margin-bottom: -1rem;
}

.assets-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.assets-header h2 {
  font-size: 0.75rem;
  font-weight: 600;
}

.assets-count {
  font-size: 0.75rem;
  font-weight: 600;
  color: #f8ad06;
}

.assets-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 1rem;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.assets-grid::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.asset-item {
  position: relative;
  background-color: #000;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-snap-align: start;
}

.asset-item img {
  width: 4rem;
  height: auto;
  margin-bottom: 0.5rem;
}

.asset-item.locked {
  opacity: 0.7;
}

.lock-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
}

.leaderboard-container {
  width: 80%;
  padding: 1rem 2rem;
  background-color: rgba(35, 39, 42, 0.7);
  border-radius: 1rem;
  margin-top: 1rem;
  overflow-y: scroll;
  max-height: 18rem;

}

.leaderboard-header {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.leaderboard-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.leaderboard-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leaderboard-avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.leaderboard-name {
  flex-grow: 1;
  font-size: 1rem;
}

.leaderboard-points {
  font-size: 1rem;
  font-weight: 600;
}

.points-label {
  color: #f8ad06;
  margin-left: 0.5rem;
}

@media (max-width: 380px) {
  .leaderboard-container {
    margin-top: 0.5rem;
  }

  .assets-container {
    margin-top: 0.5rem;
  }

  .leaderboard-container {
    max-height: 11rem;
  }
}
