@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

.store-container {
	background-color: #1a1a1a;
	color: white;
	height: 100%;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

.store-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	background-color: #23272a;
}

.store-header h2 {
	font-size: 1.5rem;
	margin: 0;
}

.wallet-balance {
	display: flex;
	align-items: center;
}

.wallet-balance img {
	width: 1.5rem;
	height: 1.5rem;
	margin-right: 0.5rem;
}

.store-tabs {
	display: flex;
	justify-content: space-evenly;
	padding: 1rem;
	margin-top: 8rem;
}

.store-tabs button {
	background: none;
	border: none;
	color: #9c9c9c;
	font-size: 1rem;
	padding: 0.5rem 1rem;
	cursor: pointer;
	transition: color 0.3s ease;
	font-family: "QK Star", sans-serif;
}

.store-tabs button.active {
	color: #f8ad06;
}

.store-content {
	width: 90%;
	border-radius: 1rem 1rem 0 0;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.store-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	background-color: #f8ad06;
	border-radius: 1rem;
	padding: 1rem;
}

.active-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: auto;
}

.active-item img {
	width: 7rem;
	height: 7rem;
	object-fit: contain;
	margin-bottom: 0rem;
	animation: bounce 2s ease-in-out infinite;
}

.shipcontainer {
	position: relative;
	width: 7rem;
	height: 7rem;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
}

.shadow {
	position: absolute;
	bottom: -1rem;
	width: 80px;
	height: 20px;
	background: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	animation: shadowPulse 2s ease-in-out infinite;
}

@keyframes bounce {

	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-20px);
	}
}

@keyframes shadowPulse {

	0%,
	100% {
		transform: scale(1);
		opacity: 0.2;
	}

	50% {
		transform: scale(0.8);
		opacity: 0.1;
	}
}

.active-item h4 {
	margin-bottom: 0rem;
}

.active-item p {
	color: #000;
	margin: 0;
}

.price {
	color: #000;
}

.active-item button {
	font-family: "QK Star", sans-serif;
	padding: 0.5rem 1rem;
	background-color: #000;
	color: #f8ad06;
	width: 100%;
}

.other-items {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
}

.other-buy-button {
	all: unset;
	font-size: .5rem;
	font-family: "JetBrains Mono", monospace;
}

.store-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #000;
	border-radius: 16px;
	padding: 0.5rem;
	position: relative;
}

.store-lock {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 8px;
	left: 8px;
}

.store-item .store-image {
	width: 40px;
	height: 40px;
	object-fit: contain;
	margin-bottom: 0.25rem;
}

.store-item h4 {
	font-size: 0.8rem;
	margin: 0;
	color: white;
	text-align: center;
}

.buy-button {
	background-color: #f8ad06;
	color: #000000;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
	transition: background-color 0.3s ease;
	margin-top: 0.5rem;
}

.store-item.active {
	flex-direction: column;
	padding: 1rem;
}

.store-item.active img {
	width: 80px;
	height: 80px;
	margin-right: 0;
	margin-bottom: 0.5rem;
}

.store-item.active .buy-button {
	margin-left: 0;
	margin-top: 0.5rem;
}

@media (max-width: 768px) {

	.store-content {
		width: 100%;
	}

	.store-items {
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
	}

	.store-item .store-image {
		width: 56px;
		height: 56px;
	}

	.store-item h3 {
		font-size: 0.9rem;
	}

	.store-item p {
		font-size: 0.7rem;
	}

	.buy-button {
		padding: 0.4rem 0.8rem;
		font-size: 0.9rem;
	}
}

@media (max-width: 380px) {
	.store-content {
		height: 18rem;
	}
}