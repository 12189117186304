.referral-page {
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.referral-page h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #4CAF50;
}

.referral-link {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.referral-link input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #333;
  background-color: #2a2a2a;
  color: white;
}

.referral-link button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.referral-link button:hover {
  background-color: #45a049;
}

.close-button {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #d32f2f;
}

@media (max-width: 600px) {
  .referral-page {
    padding: 15px;
  }

  .referral-page h2 {
    font-size: 20px;
  }

  .referral-link input,
  .referral-link button {
    font-size: 14px;
  }

  .close-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
