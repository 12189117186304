.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
}

.chicken-logo {
  width: 8rem;
  height: 8rem;
  margin-top: -4rem;
}

.loading-text {
  font-family: "QK Star", sans-serif;
  color: #ffffff;
  text-align: center;
  font-size: 1.5rem;
  max-width: 19rem;
  margin-bottom: 20rem;
}

.loading-bar-container {
  width: 100%;
  height: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  background-color: #f8ad06;
  transition: width 0.3s ease-in-out;
}

@media (max-width: 700px) {
  .chicken-logo {
    width: 8rem;
    height: 8rem;
  }

  .loading-text {
    margin-bottom: 15rem;
    font-size: 0.8rem;
  }

  .loading-bar-container {
    height: 5px;
    width: 75%;
  }
}
